<template>
    <div class="container" title="企业信息">
        <h5 class="my-2 px-2 py-1 bg-white">{{ data.name }}</h5>
        <div class="p-2 bg-white">
            <p class="m-0 fs-9"><b>岗位: </b><span v-for="(value, code) in data.jobs" :key="code"> {{ value.name }} | </span> </p>
            <p class="font-weight-bold fs-9">福利: <span v-for="(value, code) in data.welfare" :key="code"> {{ WELFARE[value] }} | </span></p>
            <p class="font-weight-bold fs-9">简介: </p>

            <p class="ml-3">
                {{ data.description }}
            </p>
            <p><b>地址: </b>{{ data.address }}</p>
            <img class="img-fluid py-1" v-for="(value, code) in data.imags" :key="code" :src="value" alt="" />

            <div class="title" align='right'>

            </div>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue'
import { NATURE, COMPANY_TYPE, WELFARE } from "@/lib/data"
export default {
    name: "CompanyInfo",
    setup() {
        const data = ref()

        data.value = 
            {
                id: 100,
                name: "一二一通信苏州有限公司",
                logo: "/imgs/logo.jpg",
                imags: ["/imgs/h01.jpg", "/imgs/h02.jpg", "/imgs/h03.jpg"],
                address: "苏州工业园区1111",
                contact: "simon",
                contact_tel: "180180180180",
                creater: { id: 1000, name: "jcccc" },
                create_time: "2022-01-01",
                nature: 0,
                type: 0,
                welfare:[0,3,4,5],
                jobs:[
                    {"id":100,"name":"人事主管"},
                    {"id":102,"name":"技术主管"}
                ],
                description:"本公司目前主要业务为人力资源服务，主要对接个人，团队，学校，中介，企业等．  <br> ２、不怕您敢想，就怕您想不到－－您的梦想有多大，成就就会有多大．放飞自我，展翅高飞．<br>"
            }


        return {
            WELFARE,
            NATURE,
            COMPANY_TYPE,
            data
        }
    }

}
</script>

<style>

</style>


